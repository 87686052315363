import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Spinner, Text } from '@chakra-ui/react'
import { setCurrentAccount, setNewProvider, setCurrentNetwork } from '@/store/features/wallet/reducer'
import useWeb3Auth from '@/hooks/useWeb3Auth'
import RPC from '@/utils/ethersRPC'
import { SafeEventEmitterProvider } from '@web3auth/base'

interface IBalanceButton {
  text: string
}

const SignIn = (props: IBalanceButton) => {
  const { text } = props
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { login } = useWeb3Auth()

  const logIn = async (): Promise<void> => {
    setIsLoading(true)
    const web3authProvider: SafeEventEmitterProvider | null = await login()
    dispatch(setNewProvider(web3authProvider))
    if (web3authProvider) {
      const rpc = new RPC(web3authProvider)
      const address = await rpc.getAccounts()
      const chainId = await rpc.getChainId()
      dispatch(setCurrentAccount(address))
      dispatch(setCurrentNetwork(chainId))
    }
  }

  return (
    <Button {...props} onClick={logIn}>
      <Text>{text}</Text>
      {isLoading && (
        <Spinner ml={'8px'} thickness="2px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="sm" />
      )}
    </Button>
  )
}
export default SignIn
