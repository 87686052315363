import React from 'react'
import { HStack, Text, Flex, Box } from '@chakra-ui/react'
import LogoSvg from '@/components/Icons/Logo'
interface IProps {
  isWhite?: boolean
  isDashboard?: boolean
  display?: string
  isHide?: boolean
}

const Logo: React.FC<IProps> = (props: IProps) => {
  return (
    <HStack>
      <Box display={props.isHide ? 'none' : 'block'}>
        <LogoSvg size={{ base: '24px', md: '28px' }} color={props.isWhite ? '#fff' : '#000'} />
      </Box>
      <Flex position={'relative'}>
        <Text
          fontWeight="700"
          fontSize="24px"
          lineHeight="116%"
          color={props.isWhite ? '#fff' : '#000'}
          transition="all 0.1s ease-out"
          display={{ base: 'none', md: 'inline-block' }}
          {...props}
        >
          CryptoMarry
        </Text>
        {(props.isDashboard && (
          <Box
            py="1px"
            px="6px"
            borderRadius="6px"
            fontWeight="500"
            fontSize="16px"
            lineHeight="20px"
            color="#E2701D"
            mt="4px"
            position={'absolute'}
            right="-54px"
            top="-6px"
            display={{ base: 'none', md: 'inline-block' }}
          >
            Alpha
          </Box>
        )) ||
          null}
      </Flex>
    </HStack>
  )
}

export default Logo
