import { useState, useEffect } from 'react'

interface IArgs {
  (selector: string, rootMargin?: string | undefined): boolean
}

const useIntersection: IArgs = (selector, rootMargin) => {
  const [isVisible, setState] = useState(false)

  useEffect((): (() => any) => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting)
      },
      { rootMargin, threshold: 0 },
    )

    const element = document.querySelector(selector)

    element && observer.observe(element)

    return () => element && observer.unobserve(element)
  }, [])

  return isVisible
}

export default useIntersection
