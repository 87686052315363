/* eslint-disable no-unused-vars */
import { ethers } from 'ethers'
import { INFURA_ID, NEXT_PUBLIC_ALCHEMY_API_POLYGON } from '../configs'

export function createProvider(chainId) {
  let MAIN_ENDPOINT
  let CHAINNUMBER
  if (chainId === '0x89') {
    MAIN_ENDPOINT = `https://polygon-mainnet.g.alchemy.com/v2/${NEXT_PUBLIC_ALCHEMY_API_POLYGON}`
    CHAINNUMBER = 137
  } else if (chainId === '0x1') {
    MAIN_ENDPOINT = `https://mainnet.infura.io/v3/${INFURA_ID}`
    CHAINNUMBER = 1
  } else {
    MAIN_ENDPOINT = `https://goerli.infura.io/v3/${INFURA_ID}`
    CHAINNUMBER = 5
  }

  return new ethers.providers.JsonRpcProvider(MAIN_ENDPOINT, CHAINNUMBER)
}
