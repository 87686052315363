import React from 'react'
import { Box } from '@chakra-ui/react'

interface IProps {
  size?: string | object
  color?: string
}

const Logo: React.FC<IProps> = ({ size = '24px', color = '#fff' }) => {
  return (
    <Box height={size}>
      <svg height="100%" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 7L14 0H7L0 7V14L7 21L14 28L21 35L28 28L35 21L42 14V7L35 0H28L21 7Z" fill={color} />
      </svg>
    </Box>
  )
}

export default Logo
