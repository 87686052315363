import { COMPOUND_V2_VOTE_STATUSES } from './CompoundV2/types'
import { COMPOUND_V3_VOTE_STATUSES } from './CompoundV3/types'
import { UNISWAP_V3_VOTE_STATUSES } from './UniswapV3/types'

export const MARRY_STATUS_PROPOSED = 0
export const MARRY_STATUS_DECLINED = 1
export const MARRY_STATUS_CANCELLED = 2
export const MARRY_STATUS_MARRIED = 3
export const MARRY_STATUS_DIVORCED = 4
export const MARRY_STATUS_NONE = 999

export const VOTE_STATUS_NEW = 1
export const VOTE_STATUS_ACCEPTED = 2
export const VOTE_STATUS_DECLINED = 3
export const VOTE_STATUS_CANCELLED = 4

//Execution statuses
export const VOTE_STATUS_PAID = 5
export const VOTE_INVITATION_SENT = 12
export const VOTE_MEMBER_LEFT = 13
export const VOTE_STATUS_DIVORCED = 6
export const VOTE_STATUS_CLOSED = 7
export const VOTE_THRESHOLD_CHANGED = 14
export const VOTE_STATUS_INVESTED_TOKEN = 203
export const VOTE_STATUS_REDEEMED_TOKEN = 206
export const VOTE_STATUS_INVESTED_ETH = 204
export const VOTE_STATUS_REDEEMED_ETH = 205
export const VOTE_STATUS_NFT_SENT = 10

//Other statuses
export const VOTE_STATUS_EXPIRED = 'expired'
export const VOTE_STATUS_NO_BALANCE = 'no-balance'
export const VOTE_STATUS_DAO = 'dao'
export const VOTE_STATUS_MULTISIG = 'multisig'
export const FAMILY_STATUS_INVITED = 0
export const FAMILY_STATUS_ACCEPTED = 1
export const FAMILY_STATUS_DECLINED = 2

export const MEMBER_STATUS_INVITED = 10
export const MEMBER_STATUS_ACCEPTED = 3
export const MEMBER_STATUS_DECLINED = 0

export const VOTE_STATUS_PERIOD_CHANGED = 11

export const FAMILY_STATUSES = {
  [MEMBER_STATUS_INVITED]: {
    name: 'Invited',
    bg: '#4299E1',
  },
  [MEMBER_STATUS_ACCEPTED]: {
    name: 'Accepted',
    bg: '#48BB78',
  },
  [MEMBER_STATUS_DECLINED]: {
    name: 'Declined or Removed',
    bg: '#F56565',
  },
}

export const VOTE_STATUSES: { [key: string]: any } = {
  [VOTE_STATUS_NEW]: {
    name: 'New',
    bg: '#4299E1',
    label: 'Status',
  },
  [VOTE_STATUS_ACCEPTED]: {
    name: 'Accepted',
    bg: '#48BB78',
    label: 'Status',
  },
  [VOTE_INVITATION_SENT]: {
    name: 'Invitation Sent',
    bg: '#48BB78',
    label: 'Status',
  },
  [VOTE_MEMBER_LEFT]: {
    name: 'Member Left',
    bg: '#48BB78',
    label: 'Status',
  },
  [VOTE_THRESHOLD_CHANGED]: {
    name: 'Threshold Updated',
    bg: '#48BB78',
    label: 'Status',
  },
  [VOTE_STATUS_DAO]: {
    name: 'DAO vote',
    bg: '#48BB78',
    label: 'Status',
  },
  [VOTE_STATUS_MULTISIG]: {
    name: 'MultiSig vote',
    bg: '#0BC5EA',
    label: 'Status',
  },
  [VOTE_STATUS_DECLINED]: {
    name: 'Declined',
    bg: '#F56565',
    label: 'Status',
  },
  [VOTE_STATUS_CANCELLED]: {
    name: 'Cancelled',
    bg: '#626262',
    label: 'Status',
  },
  [VOTE_STATUS_EXPIRED]: {
    name: 'Expired',
    bg: '#626262',
    label: 'Vote expired by date',
  },
  [VOTE_STATUS_CLOSED]: {
    name: 'Vote passed - Closed by deadline',
    bg: '#48BB78',
    label: 'Vote closed by date',
  },
  [VOTE_STATUS_DIVORCED]: {
    name: 'Dissolute',
    bg: '#E53E3E',
    label: 'Status',
  },
  [VOTE_STATUS_REDEEMED_TOKEN]: {
    name: 'Redeemed',
    bg: '#0BC5EA',
    label: 'Status',
  },
  [VOTE_STATUS_PAID]: {
    name: 'Paid',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_STATUS_REDEEMED_ETH]: {
    name: 'Redeemed',
    bg: '#0BC5EA',
    label: 'Status',
  },
  [VOTE_STATUS_INVESTED_TOKEN]: {
    name: 'Supplied',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_STATUS_INVESTED_ETH]: {
    name: 'Supplied',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_STATUS_NFT_SENT]: {
    name: 'NFT Sent',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_STATUS_NO_BALANCE]: {
    name: 'Not enough Balance',
    bg: '#ED8936',
    label: 'Not Enough Balance to execute the proposal',
  },
  [VOTE_STATUS_PERIOD_CHANGED]: {
    name: 'Minimum period changed',
    bg: '#4299E1',
    label: 'Status',
  },
  ...UNISWAP_V3_VOTE_STATUSES,
  ...COMPOUND_V2_VOTE_STATUSES,
  ...COMPOUND_V3_VOTE_STATUSES,
}
