import { CONTRACTS } from '@/configs/contracts'
import { Network, Alchemy } from 'alchemy-sdk'

interface INetworks {
  [key: string]: Network
}

export const getAlchemy = (currentNetwork: string) => {
  const networks: INetworks = {
    '0x5': Network.ETH_GOERLI,
    '0xaa36a7': Network.ETH_SEPOLIA,
  }
  const settings = {
    apiKey: CONTRACTS[currentNetwork].alchemy,
    network: networks[currentNetwork],
  }

  return new Alchemy(settings)
}
