export const LANDING_PAGE = '/'
export const DASHBOARD_PAGE = '/dashboard'
export const WRONG_PAGE = '/wrong-network'
export const REGISTRATION_PAGE = '/registration'
export const ACCEPT_PROPOSAL_PAGE = '/accept-proposal'
export const FAMILY_ONBOARD_PAGE = '/family-onboard'
export const TOS_PAGE = '/tos'
export const PRIVACY_PAGE = '/privacy'
export const ROUTER = '/reload'
export const SETTINGS_PAGE = '/settings'
