export const VOTE_TYPE_SWAP_ETH_UV3 = 101
export const VOTE_TYPE_SWAP_TOKEN_UV3 = 103
export const VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3 = 102

export const VOTE_TYPE_SWAP_ETH_UV3_OUTPUT = 107
export const VOTE_TYPE_SWAP_TOKEN_UV3_OUTPUT = 109
export const VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3_OUTPUT = 108

export const UNISWAP_V3_VOTE_TYPES = {
  [VOTE_TYPE_SWAP_TOKEN_UV3]: {
    name: 'Swap Token Uniswap V3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_SWAP_ETH_UV3]: {
    name: 'Swap ETH Uniswap V3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3]: {
    name: 'Swap Token/ETH Uniswap V3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_SWAP_TOKEN_UV3_OUTPUT]: {
    name: 'Swap Token Uniswap V3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_SWAP_ETH_UV3_OUTPUT]: {
    name: 'Swap ETH Uniswap V3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3_OUTPUT]: {
    name: 'Swap Token/ETH Uniswap V3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
}

export const UNISWAP_V3_VOTE_STATUSES = {
  [VOTE_TYPE_SWAP_ETH_UV3]: {
    name: 'ETH Swap Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3]: {
    name: 'Token Swap Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_SWAP_TOKEN_UV3]: {
    name: 'Token/ETH Swap Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_SWAP_ETH_UV3_OUTPUT]: {
    name: 'ETH Swap Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3_OUTPUT]: {
    name: 'Token Swap Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_SWAP_TOKEN_UV3_OUTPUT]: {
    name: 'Token/ETH Swap Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
}

export function isUniswapVoteType(type: number): boolean {
  return [
    VOTE_TYPE_SWAP_TOKEN_UV3,
    VOTE_TYPE_SWAP_ETH_UV3,
    VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3,
    VOTE_TYPE_SWAP_ETH_UV3_OUTPUT,
    VOTE_TYPE_SWAP_TOKEN_UV3_OUTPUT,
    VOTE_TYPE_SWAP_TOKEN_TO_ETH_UV3_OUTPUT,
  ].includes(type)
}
