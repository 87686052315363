export const VOTE_TYPE_REDEEM_ETH = 812
export const VOTE_TYPE_REDEEM_TOKEN = 814
export const VOTE_TYPE_INVEST_ETH = 810
export const VOTE_TYPE_INVEST_TOKEN = 811
export const VOTE_TYPE_BORROW_TOKEN = 816
export const VOTE_TYPE_BORROW_ETH = 817
export const VOTE_TYPE_REPAY_TOKEN = 818
export const VOTE_TYPE_REPAY_ETH = 819

export const COMPOUND_V2_VOTE_TYPES = {
  [VOTE_TYPE_REDEEM_ETH]: {
    name: 'Redeem ETH',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_REDEEM_TOKEN]: {
    name: 'Redeem Token',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_INVEST_ETH]: {
    name: 'Invest ETH',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_INVEST_TOKEN]: {
    name: 'Invest Token',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_BORROW_ETH]: {
    name: 'Borrow ETH',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_BORROW_TOKEN]: {
    name: 'Borrow Token',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_REPAY_ETH]: {
    name: 'Repay ETH',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_REPAY_TOKEN]: {
    name: 'Repay Token',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
}

export const COMPOUND_V2_VOTE_STATUSES = {
  [VOTE_TYPE_REDEEM_ETH]: {
    name: 'Redeem ETH Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
  [VOTE_TYPE_REDEEM_TOKEN]: {
    name: 'Redeem Token Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
  [VOTE_TYPE_INVEST_ETH]: {
    name: 'Invest ETH Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
  [VOTE_TYPE_INVEST_TOKEN]: {
    name: 'Invest Token Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
  [VOTE_TYPE_BORROW_ETH]: {
    name: 'Borrow ETH Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
  [VOTE_TYPE_BORROW_TOKEN]: {
    name: 'Borrow Token Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
  [VOTE_TYPE_REPAY_ETH]: {
    name: 'Repay ETH Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
  [VOTE_TYPE_REPAY_TOKEN]: {
    name: 'Repay Token Submitted',
    bg: '#38B2AC',
    label: 'Vote type',
  },
}

export function isCompoundV2VoteType(type: number): boolean {
  return [
    VOTE_TYPE_REDEEM_ETH,
    VOTE_TYPE_REDEEM_TOKEN,
    VOTE_TYPE_INVEST_ETH,
    VOTE_TYPE_INVEST_TOKEN,
    VOTE_TYPE_BORROW_ETH,
    VOTE_TYPE_BORROW_TOKEN,
    VOTE_TYPE_REPAY_TOKEN,
    VOTE_TYPE_REPAY_ETH,
  ].includes(type)
}
