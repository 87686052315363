import { useContext } from 'react'
import { Web3AuthContext } from '@/providers/web3auth'
import { setUserInfo } from '@/store/features/contract/reducer'
import { useDispatch } from 'react-redux'
import { SafeEventEmitterProvider } from '@web3auth/base'

const NOT_PROVIDED = 'web3auth not initialized yet'

const useWeb3Auth = () => {
  const dispatch = useDispatch()
  const { web3Auth, setProvider } = useContext(Web3AuthContext)

  const getUserInfo = async () => {
    if (!web3Auth) throw NOT_PROVIDED
    try {
      const user = await web3Auth.getUserInfo()
      return user
    } catch (error) {
      throw error
    }
  }

  const login = async (): Promise<SafeEventEmitterProvider | null> => {
    if (!web3Auth) throw NOT_PROVIDED
    try {
      const web3authProvider = await web3Auth.connect()
      const userInfo = await getUserInfo()

      dispatch(setUserInfo(userInfo))
      setProvider(web3authProvider)
      return web3authProvider
    } catch (error) {
      throw error
    }
  }

  const logout = async () => {
    if (!web3Auth) throw NOT_PROVIDED
    try {
      await web3Auth.logout()
      setProvider(null)
    } catch (error) {
      throw error
    }
  }

  const authenticateUser = async () => {
    if (!web3Auth) throw NOT_PROVIDED
    try {
      const idToken = await web3Auth.authenticateUser()
      return idToken
    } catch (error) {
      throw error
    }
  }

  return {
    login,
    logout,
    authenticateUser,
    getUserInfo,
  }
}

export default useWeb3Auth
