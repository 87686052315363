// Supply
export const VOTE_TYPE_INVEST_ETH = 800
export const VOTE_TYPE_INVEST_TOKEN = 801
export const VOTE_TYPE_INVEST_USDC = 802

// Withdraw
export const VOTE_TYPE_REDEEM_ETH = 803
export const VOTE_TYPE_REDEEM_TOKEN = 804
//Borrow Repay
export const VOTE_TYPE_BORROW_TOKEN = 805
export const VOTE_TYPE_REPAY_TOKEN = 806

export const COMPOUND_V3_VOTE_TYPES = {
  [VOTE_TYPE_REDEEM_ETH]: {
    name: 'Withdraw ETH',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_REDEEM_TOKEN]: {
    name: 'Withdraw Token',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_INVEST_ETH]: {
    name: 'Provide collateral ETH',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_INVEST_TOKEN]: {
    name: 'Provide collateral Token',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_INVEST_USDC]: {
    name: 'Supply USDC',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
  [VOTE_TYPE_BORROW_TOKEN]: {
    name: 'Borrow USDC CompoundV3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },

  [VOTE_TYPE_REPAY_TOKEN]: {
    name: 'Repay USDC CompoundV3',
    bg: '#C0BEBE',
    label: 'Vote type',
  },
}

export const COMPOUND_V3_VOTE_STATUSES = {
  [VOTE_TYPE_INVEST_ETH]: {
    name: 'ETH Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_INVEST_TOKEN]: {
    name: 'Token Submitted',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_INVEST_USDC]: {
    name: 'USDC Staked',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_REDEEM_ETH]: {
    name: 'ETH Withdrawed',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_REDEEM_TOKEN]: {
    name: 'Token Withdrawed',
    bg: '#38B2AC',
    label: 'Status',
  },
  [VOTE_TYPE_REPAY_TOKEN]: {
    name: 'Loan Repayed',
    bg: '#38B2AC',
    label: 'Status',
  },
}

export function isCompoundV3VoteType(type: number): boolean {
  return [
    VOTE_TYPE_REDEEM_ETH,
    VOTE_TYPE_REDEEM_TOKEN,
    VOTE_TYPE_INVEST_ETH,
    VOTE_TYPE_INVEST_TOKEN,
    VOTE_TYPE_INVEST_USDC,
    VOTE_TYPE_BORROW_TOKEN,
    VOTE_TYPE_REPAY_TOKEN,
  ].includes(type)
}
